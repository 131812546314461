<template>
  <div>
    <v-expand-transition>
      <div v-show="showDetails" :class="{ 'px-3': showDetails, 'py-3': showDetails }">
        <slot name="detail" :item="details">
          <v-row class="flex justify-end">
            <v-col cols="12" lg="3" sm="6" v-for="(detail, id) in details" :key="`detail-${id}`">
              <v-card outlined>
                <v-card-text class="pa-5">
                  <div class="d-flex align-center">
                    <v-btn :color="detail.bgIconColor" class="elevation-0" fab dark>
                      <Icon :icon="detail.icon" width="32" color="white" />
                    </v-btn>
                    <div class="ml-2 mr-1">
                      <h2 class="headline font-weight-light">
                        {{ detail.title }}
                      </h2>
                      <h5 class="subtitle-2 font-weight-regular">
                        {{ detail.subtitle }}
                      </h5>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </slot>
      </div>
    </v-expand-transition>

    <v-data-table
      ref="ex-data-table-ref"
      v-model="valueTable"
      :headers="headerTable"
      :items="rowsFilterTable"
      :search="search"
      multi-sort
      :mobile-breakpoint="0"
      :items-per-page="10"
      :footer-props="{
        'items-per-page-options': [10, 50, 100, 200, -1],
      }"
      no-results-text="No se encontraron datos"
      no-data-text="No se encontraron datos"
      loading-text="Cargando datos..."
      item-key="idx"
      :loading="showLoading"
      :calculate-widths="true"
      dense
      @contextmenu.native.prevent
      @pagination="currentItemsLength"
      :height="heightTable"
      :single-select="!multiple"
      hide-default-header
      v-shortkey="['alt', 'm']"
      @shortkey.native="changeShowDetails"
    >
      <!--:single-select="true" :show-select="true" :height="heightTable"-->
      <template v-slot:header="{ props, on }">
        <thead class="v-data-table-header">
          <tr>
            <th
              v-for="(el, i) in props.headers"
              :key="`tr-th${el.value}${i}`"
              role="columnheader"
              scope="col"
              aria-sort="none"
              :class="[
                'text-center',
                ...el.class,
                { sortable: el.sortable },
                { active: props.options.sortBy.indexOf(el.value) !== -1 && el.sortable },
                { asc: !props.options.sortDesc[props.options.sortBy.indexOf(el.value)] && el.sortable },
                { desc: props.options.sortDesc[props.options.sortBy.indexOf(el.value)] && el.sortable },
              ]"
              :style="`width: ${el.width}px; min-width: ${el.width}px; ${el.isSticky ? `position: sticky; left: ${el.left}px` : ``}`"
              @click="el.value !== 'data-table-select' ? on.sort(el.value) : null"
              @contextmenu.prevent="el.value !== 'data-table-select' ? filterContextMenu($event, el.value) : null"
            >
              <template v-if="el.value === 'data-table-select' && multiple">
                <v-simple-checkbox @input="on['toggle-select-all'](!props.everyItem)" :value="props.everyItem" :indeterminate="props.someItems" :ripple="false"></v-simple-checkbox>
              </template>
              <template v-else>
                <span>{{ el.text }}</span>
                <!--              <span>{{ props.options }}</span>-->
                <div v-if="el.isFilter" class="d-inline-flex">
                  <span class="ml-1">[</span>
                  <Icon icon="custom-mdi-filter-menu" color="primary" width="18" />
                  <span>{{ el.orderFilter }}</span>
                  <span>]</span>
                </div>
                <span aria-hidden="true" class="v-icon notranslate v-data-table-header__icon theme--light" style="font-size: 18px; height: 18px; width: 18px">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg" style="font-size: 18px; height: 18px; width: 18px">
                    <path d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z"></path>
                  </svg>
                </span>
                <span v-if="props.options.sortBy.indexOf(el.value) !== -1" class="v-data-table-header__sort-badge">
                  {{ props.options.sortBy.indexOf(el.value) + 1 }}
                </span>
              </template>
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:top>
        <v-toolbar flat dense color="primary" class="rounded-t">
          <v-toolbar-title class="white--text font-weight-medium">{{ settings.title !== undefined ? settings.title : "TITLE" }}</v-toolbar-title>
          <v-divider class="mx-4" vertical></v-divider>
          <v-text-field v-if="searchShow" label="Buscar" autocomplete="off" clearable background-color="white" rounded single-line hide-details @input="nextSearchHeader($event)">
            <template v-slot:append>
              <Icon icon="custom-mdi-magnify" :color="$vuetify.theme.themes.light.primary" width="24" />
            </template>
          </v-text-field>
          <v-divider v-if="searchShow" class="mx-4" vertical></v-divider>
          <v-select
            v-if="fixedColumnsShow"
            v-model="stickyHeaders"
            :items="stickyItemsHeader"
            label="Fijar columnas"
            multiple
            clearable
            background-color="white"
            :allow-overflow="false"
            :disable-lookup="true"
            no-filter
            rounded
            single-line
            hide-details
            return-object
          >
            <template v-slot:selection="{ item, index }">
              <v-chip small v-if="index === 0">
                <span>{{ item.text }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption"> (+{{ stickyHeaders.length - 1 }} {{ stickyHeaders.length > 2 ? "campos" : "campo" }}) </span>
            </template>
          </v-select>
          <v-divider class="mx-4" vertical></v-divider>
          <v-toolbar-title class="white--text font-weight-medium"> {{ !valueTable.length ? `${countRowsTable}` : `${valueTable.length}/${countRowsTable}` }} </v-toolbar-title>
          <v-divider class="mx-4" vertical></v-divider>
          <v-btn v-if="loadingBtnOptions" loading small color="transparent" elevation="0" dark fab> </v-btn>
          <v-speed-dial v-else v-model="options" direction="bottom" :open-on-hover="false" transition="scale-transition" @click.native.stop>
            <template v-slot:activator>
              <v-btn v-model="options" small color="transparent" elevation="0" dark fab>
                <Icon v-if="options" icon="custom-mdi-close" width="24" />
                <Icon v-else icon="custom-mdi-dots-vertical" width="24" />
              </v-btn>
            </template>
            <template v-for="(el, i) in listOptions">
              <v-tooltip v-if="el.show" :key="`opt-${i}`" left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn fab small :color="el.color" v-bind="attrs" v-on="on" @click="eventOptionItems(el.id)">
                    <Icon :icon="el.icon" width="24" color="white" />
                  </v-btn>
                </template>
                <span>{{ el.tooltip }}</span>
              </v-tooltip>
            </template>
          </v-speed-dial>
        </v-toolbar>
      </template>

      <template v-slot:body="{ items, headers, isSelected, select }">
        <tbody v-if="!items.length">
          <tr class="v-data-table__empty-wrapper">
            <td colspan="0">No se encontraron datos</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="(item, id) in items" :key="`tr-${id}`" class="" @click="selectRow($event, item, select, select(item, !isSelected(item)))" @contextmenu="eventContextMenu($event, item, select)">
            <td
              v-for="(header, i) in headers"
              :key="`td-${header.value}${id}${i}`"
              :class="['text-center', { 'grey lighten-2': isSelected(item) }, ...header.cellClass]"
              :style="header.isSticky ? `position: sticky; left: ${header.left}px` : ``"
            >
              <v-simple-checkbox v-if="header.value === 'data-table-select'" :value="isSelected(item)" @input="select(item, !isSelected(item))" :ripple="false"></v-simple-checkbox>
              <span v-else class>{{ item[header.value] }} </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-menu
      ref="v-menu-filter-table"
      v-model="showFilterMenu"
      :position-x="showFilterMenuX"
      :position-y="showFilterMenuY"
      :close-on-content-click="false"
      min-width="450"
      max-width="450"
      nudge-width="400"
      transition="slide-y-transition"
      bottom
    >
      <v-card>
        <v-card-title>
          <v-text-field v-model="txtSearchMenu" autocomplete="off" clearable class="mx-2" label="Buscar" @input="nextSearchFilter($event)"></v-text-field>
        </v-card-title>

        <v-card-text>
          <ex-lazy-list :data="listFiltersTable" :height="300" :item-height="40" tag="div" item-tag="div">
            <template slot-scope="{ item }">
              <v-checkbox v-model="item.isActive" class="mx-2" hide-details>
                <template v-slot:label>
                  <span>{{ item.value }}</span>
                </template>
              </v-checkbox>
            </template>
          </ex-lazy-list>
        </v-card-text>

        <v-card-actions class="justify-space-around">
          <v-btn @click="closeFilterMenu" text color="red"> Cerrar </v-btn>
          <v-btn icon color="#ffb22b" @click="clearFilterMenu">
            <Icon icon="custom-mdi-broom" width="24" />
          </v-btn>
          <v-btn @click="acceptFilterMenu" text color="primary"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <vue-context v-if="contextItems.length" ref="v-context-menu">
      <template v-for="(el, i) in contextItems">
        <li v-if="el.show" :key="`ctx-${i}`">
          <div class="d-flex align-center my-3 mr-4" style="cursor: pointer" @click.prevent="clickContextMenu(el.id)">
            <Icon class="mr-3" :icon="el.icon" width="24" :color="el.color" />
            <span>{{ el.text }}</span>
          </div>
        </li>
      </template>
    </vue-context>
  </div>
</template>
<script>
import { Icon } from "@iconify/vue2/dist/offline";
import VueContext from "vue-context";
import "vue-context/src/sass/vue-context.scss";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

export default {
  name: "ExDataTable",
  components: { Icon, VueContext },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    contextItems: {
      type: Array,
      default: () => [],
    },
    optionItems: {
      type: Array,
      default: () => [],
    },
    showDetails: {
      type: Boolean,
      default: true,
    },
    details: {
      type: Array,
      default: () => [
        // {
        //   subtitle: "Total de ingreso",
        //   bgIconColor: "info",
        //   icon: "custom-mdi-currency-usd",
        //   title: "$3249",
        // },
        // {
        //   subtitle: "Total de egreso",
        //   bgIconColor: "warning",
        //   icon: "custom-mdi-currency-usd-off",
        //   title: "$2376",
        // },
        // {
        //   subtitle: "Personal seleccionado",
        //   bgIconColor: "deep-purple accent-2",
        //   icon: "custom-mdi-account",
        //   title: "10/679",
        // },
        {
          id: "cod_po",
          subtitle: "Personal observado",
          bgIconColor: "error",
          icon: "custom-mdi-account-cancel",
          title: "--/--",
        },
      ],
    },
    itemsFilter: {
      type: Object,
      default: () => ({}),
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    defaultOptions: {
      type: Boolean,
      default: false,
    },
    loadingBtnOptions: {
      type: Boolean,
      default: false,
    },
    simpleDetail: {
      type: Boolean,
      default: false,
    },
    fixedColumnsShow: {
      type: Boolean,
      default: true,
    },
    searchShow: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    filtersTable: {},
    headerTable: [],
    rowsTable: [],
    rowsFilterTable: [],
    search: "",
    showLoading: false,
    searchHeader$: new Subject(),
    searchFilter$: new Subject(),
    showFilterMenu: false,
    showFilterMenuX: 0,
    showFilterMenuY: 0,
    idHeaderFilterMenu: "",
    listFiltersTable: [],
    txtSearchMenu: "",
    countCurrentItemsLength: 0,
    heightTable: "auto",
    stickyHeaders: [],
    valueTable: [],
    options: false,
    firstItemCtrl: null,
    listOptions: [],
    defaultOptionItems: [
      { id: "api-export", tooltip: "Exportar Excel", icon: "custom-mdi-microsoft-excel", color: "teal darken-3", show: true },
      { id: "simple-analytics", tooltip: "Analíticas Simple (Alt + M)", icon: "custom-mdi-chart-box-outline", color: "teal darken-3", show: true },
      // { id: "clean-filters", tooltip: "Limpiar Filtros", icon: "custom-mdi-broom", color: "#ffb22b", show: true },
    ],
    // -------------------------------
  }),
  computed: {
    countRowsTable() {
      return this.countCurrentItemsLength;
    },
    stickyItemsHeader() {
      return !this.headerTable.length ? [] : this.headerTable.filter((el) => el.value !== "data-table-select");
    },
  },
  watch: {
    optionItems(value) {
      this.listOptions = this.defaultOptions ? [...this.defaultOptionItems, ...value] : [...value];
    },
    valueTable(value) {
      this.$emit("input", value);
    },
    headers(value) {
      this.headerTable = this.helperCalculateWidth(value);
    },
    items(value) {
      const rowsMap = value.map((el, idx) => ({ idx: idx + 1, ido: idx + 1, ...el }));
      this.rowsTable = rowsMap;
      this.rowsFilterTable = rowsMap;
      this.createFilters(this.rowsTable);
    },
    // eslint-disable-next-line no-unused-vars
    itemsFilter(value) {
      // this.filtersTable = value;
    },
    stickyHeaders(value) {
      const selectHeader = this.headerTable.filter((el) => el.value === "data-table-select");
      const bodyHeader = this.headerTable.filter((el) => el.value !== "data-table-select");

      const headerTable = bodyHeader.map((el) => {
        const iCls = el.class.indexOf("border-right-inset");
        const iCell = el.cellClass.indexOf("border-right-inset");
        if (iCls !== -1) el.class.splice(iCls, 1);
        if (iCell !== -1) el.cellClass.splice(iCell, 1);
        el.isSticky = false;
        return el;
      });

      if (!value.length) {
        this.headerTable = [...selectHeader, ...headerTable];
        return;
      }

      const keys = value.map((el) => el.value);
      const left = [...selectHeader, ...headerTable.filter((el) => keys.indexOf(el.value) !== -1)];
      const right = headerTable.filter((el) => keys.indexOf(el.value) === -1);

      left.map((el, idx, arr) => {
        el.isSticky = true;
        el.left = idx === 0 ? 0 : arr[idx - 1].left + arr[idx - 1].width;
        if (arr.length === idx + 1) {
          el.cellClass.push("border-right-inset");
          el.class.push("border-right-inset");
        }
        return el;
      });

      this.headerTable = [...left, ...right];

      console.log("stickyHeaders left", left);
      console.log("stickyHeaders right", right);
    },
  },
  created() {
    this.searchHeader$.asObservable().pipe(debounceTime(300)).subscribe(this.subsSearchHeader);
    this.searchFilter$.asObservable().pipe(debounceTime(300)).subscribe(this.subsSearchFilter);
  },
  mounted() {
    this.listOptions = this.defaultOptions ? [...this.defaultOptionItems, ...this.optionItems] : [...this.optionItems];
    this.headerTable = this.helperCalculateWidth(this.headers);
    this.rowsTable = this.items;
    this.rowsFilterTable = this.items.map((el, idx) => ({ idx: idx + 1, ido: idx + 1, ...el }));
    this.createFilters(this.rowsTable);
  },
  beforeDestroy() {
    this.searchHeader$.unsubscribe();
    this.searchFilter$.unsubscribe();
  },
  methods: {
    closeDetails() {
      this.showDetails = false;
    },
    openDetails() {
      this.showDetails = true;
    },
    changeShowDetails() {
      console.log("Asdasdasdas");
      this.showDetails = !this.showDetails;
    },
    // tableToggleSelectAll(value) {
    //   if (value) this.valueTable = [...this.rowsFilterTable];
    //   else this.valueTable = [];
    // },
    // eslint-disable-next-line no-unused-vars
    selectRow($event, item, select, callback) {
      // if ($event.shiftKey) {
      //
      //   if (this.firstItemCtrl) {
      //     const filter = this.rowsFilterTable.filter((el) => el.ido >= Math.min(this.firstItemCtrl.ido, item.ido) && el.ido <= Math.max(this.firstItemCtrl.ido, item.ido));
      //     filter.forEach((el) => select(el, true));
      //     this.firstItemCtrl = null;
      //   } else {
      //     this.firstItemCtrl ??= item;
      //   }
      //   return;
      // }
      //
      // this.firstItemCtrl = null;
    },
    // eslint-disable-next-line no-unused-vars
    async eventContextMenu($event, item, select) {
      // this.$refs["v-context-menu"].open($event);

      if (!this.multiple) {
        for (const el of this.valueTable) {
          await select(el, false);
        }
        await select(item, true);
      } else {
        await select(item, true);
      }

      this.$emit("context", { context: this.$refs["v-context-menu"] ?? null, event: $event, item: item, items: [...this.valueTable] });
    },
    eventOptionItems(id) {
      if (id === "simple-analytics") {
        this.changeShowDetails();
        return;
      }
      this.options = false;
      this.$emit("option-click", { id: id, items: [...this.valueTable] });
    },
    clickContextMenu(id) {
      this.$emit("context-click", { id: id, items: [...this.valueTable] });
      this.valueTable = [];
    },
    createFilters(value) {
      if (!value.length) return;
      const filterHeader = {};
      const headerTmp = this.headerTable.reduce((acm, el) => ({ ...acm, [`${el.value}`]: [] }), {});
      const headerKeys = Object.keys(headerTmp);

      value.forEach((el) => {
        headerKeys.forEach((e) => {
          headerTmp[e].push(el[e]);
        });
      });

      headerKeys.forEach((e) => {
        filterHeader[e] = { order: 0, value: [...new Set(headerTmp[e])].map((e) => ({ isActive: false, value: e })) };
      });

      this.filtersTable = filterHeader;
    },
    // eslint-disable-next-line no-unused-vars
    currentItemsLength(value) {
      this.countCurrentItemsLength = value.itemsLength;
      // console.log(this.$refs["ex-data-table-ref"]);
      // console.log(this.$refs["ex-data-table-ref"]?.$el?.getBoundingClientRect() ?? "");
      // console.log(window.innerHeight);
      // console.log(window.clientHeight);
      // console.log(document.documentElement.clientHeight);
      //
      // if (this.$refs["ex-data-table-ref"]?.$el?.getBoundingClientRect() ?? false) {
      //   this.heightTable = window.innerHeight - this.$refs["ex-data-table-ref"]?.$el?.getBoundingClientRect().y - 200;
      // }
      //
      // console.log("resta", window.innerHeight, this.$refs["ex-data-table-ref"]?.$children[0]?.$children[0]?.$children[1]?.$el?.getBoundingClientRect());
      //
      // console.log("resta", window.innerHeight, this.$refs["ex-data-table-ref"]?.$el?.getBoundingClientRect().top);
      // console.log(this.heightTable);
    },
    helperCalculateWidth(header) {
      if (!header.length) return [];
      const arr = [];
      if (this.multiple)
        arr.push({
          align: "center",
          text: "",
          value: "data-table-select",
          width: 65,
          sortable: false,
          isSticky: true,
          left: 0,
          class: ["py-3", "bg-white", "border-right-inset zindex-1"],
          cellClass: ["bg-white", "border-right-inset zindex-1"],
        });
      header.forEach((el) => {
        arr.push({
          align: el.align,
          text: el.text,
          value: el.value,
          isSticky: false,
          x: 0,
          y: 0,
          isFilter: false,
          left: 0,
          orderFilter: 0,
          width: el?.width ?? el.text.length * 14 + 75,
          sortable: el?.sortable ?? true,
          class: ["py-3", "bg-white", "zindex-1"],
          cellClass: ["bg-white", "zindex-1"],
        });
      });

      return arr;
    },
    filterContextMenu($event, value) {
      this.idHeaderFilterMenu = value.toString().trim();
      this.listFiltersTable = this.filtersTable[this.idHeaderFilterMenu].value;
      this.showFilterMenu = false;
      this.showFilterMenuX = $event.clientX;
      this.showFilterMenuY = $event.clientY;
      this.$nextTick(() => {
        this.showFilterMenu = true;
      });
    },
    nextSearchHeader(search) {
      this.searchHeader$.next(search?.toString()?.trim() ?? "");
    },
    nextSearchFilter(search) {
      this.searchFilter$.next({
        id: this.idHeaderFilterMenu.trim(),
        search: search?.toString()?.trim() ?? "",
      });
    },
    subsSearchHeader(value) {
      this.search = value;
    },
    subsSearchFilter({ id, search }) {
      if (search === "") {
        this.listFiltersTable = this.filtersTable[id].value;
        return;
      }
      this.listFiltersTable = this.filtersTable[id].value.filter((item) => {
        return item.value.toString().search(new RegExp(search, "i")) !== -1;
      });
    },
    acceptFilterMenu() {
      const id = this.idHeaderFilterMenu;

      const countActiveFilter = this.filtersTable[id].value.filter((el) => el.isActive).map((el) => el.value);
      const contFilterTable = Object.keys(this.filtersTable).map((el) => ({ order: this.filtersTable[el].order }));
      const listCountOrder = contFilterTable.filter((el) => el.order > 0).map((el) => el.order);
      const order = !listCountOrder.length ? [0] : listCountOrder;
      const maxOrder = Math.max(...order);
      this.filtersTable[id].order = !countActiveFilter.length ? 0 : maxOrder + 1;

      const listObjectOrderFilterTable = Object.keys(this.filtersTable)
        .map((el) => ({
          order: this.filtersTable[el].order,
          id: el,
          value: this.filtersTable[el].value.filter((el) => el.isActive).map((el) => el.value),
        }))
        .filter((el) => el.order > 0)
        .sort((a, b) => a.order - b.order);

      /* show header filter*/

      const reduceOrderFilterTable = listObjectOrderFilterTable.reduce((acm, el, idx) => ({ ...acm, [`${el.id}`]: idx + 1 }), {});

      this.headerTable.map((el) => {
        el.isFilter = reduceOrderFilterTable[el.value] ?? false;
        el.orderFilter = reduceOrderFilterTable[el.value] ?? 0;
        return el;
      });
      /* show header filter*/

      let acmRowsTable = [...this.rowsTable];

      listObjectOrderFilterTable.forEach((element) => {
        acmRowsTable = acmRowsTable.filter((el) => element.value.some((e) => e === el[element.id]));
      });

      this.rowsFilterTable = [...acmRowsTable];

      this.closeFilterMenu();
    },
    clearFilterMenu() {
      const id = this.idHeaderFilterMenu;
      this.filtersTable[id].value = this.filtersTable[id].value.map((el) => ({ value: el.value, isActive: false, isVisible: false }));
      this.filtersTable[id].order = 0;
      this.acceptFilterMenu();
    },
    closeFilterMenu() {
      this.$nextTick(() => {
        this.showFilterMenu = false;
        this.txtSearchMenu = "";
        this.listFiltersTable = [];
      });
    },
  },
};
</script>
<style scoped>
.btn-close-detail {
}
</style>
