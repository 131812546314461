import Vue from "vue";
import "@/components4x/ExLazyList/list.scss";
import "beautify-scrollbar/dist/index.css";
import ExIcon from "@/components4x/ExIcon/ExIcon";
import ExLazyList from "@/components4x/ExLazyList/lazy-list";
import ExLazyBodyTable from "@/components4x/ExLazyBodyTable/lazy-body-table";
import ExDataTable from "@/components4x/ExDataTable/ExDataTable";

Vue.component(ExIcon.name, ExIcon);
Vue.component(ExLazyList.name, ExLazyList);
Vue.component(ExLazyBodyTable.name, ExLazyBodyTable);
Vue.component(ExDataTable.name, ExDataTable);
