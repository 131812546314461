"use strict";

import axios from "axios";
import Swal from "sweetalert2";
import { encryptData } from "../../components4x/utils/utils4x";

class HttpHandlerService {
  constructor(service, component, options) {
    this.service = service;
    this.component = component;
    this.path = null;
    this._checkPath();
    this._ckeckToken(options);
    this._checkHeaders();
    // console.log('options', options);
    this._requestAxios = axios.create();
    this._requestAxios.interceptors.response.use(this._onRequestSuccess, this._onRequestFailure);
  }

  _ckeckToken(options) {
    if (this.service.token !== undefined && this.service.token !== null && this.service.token !== "" && typeof this.service.token === "string") {
      this._activeToken = true;
      this._nameToken = this.service.token;
      return;
    }

    this._activeToken = Object.prototype.hasOwnProperty.call(options, "token") ? options.token : false;
    this._nameToken = Object.prototype.hasOwnProperty.call(options, "name") ? options.name : "token";
  }

  _checkPath() {
    if (this.service.path !== undefined && this.service.path !== null && this.service.path !== "" && typeof this.service.path === "string") {
      this.path = this.service.path;
      return;
    }

    if (process.env.VUE_APP_API_URL !== undefined && process.env.VUE_APP_API_URL !== "") {
      this.path = process.env.VUE_APP_API_URL;
      return;
    }

    throw new Error("El path es un string requerido");
  }

  _checkUri(uri) {
    if (uri === undefined || uri === null || uri === "" || typeof uri !== "string") {
      throw new Error("La uri es un string requerido para el resource");
    }
  }

  _checkHeaders() {
    const contentToken = {};
    const isNotEmptyName = localStorage.getItem(this._nameToken) !== null;

    if (this._activeToken && isNotEmptyName) {
      contentToken.Authorization = `Bearer ${localStorage.getItem(this._nameToken)}`;
    }

    if (this.service.headers === undefined || this.service.headers === null) {
      this.headers = {
        "Content-Type": "application/json",
        ...contentToken,
      };
      return;
    }

    this.headers = { ...this.service.headers, ...contentToken };
  }

  _checkMethod() {
    if (this.method === undefined || this.method === null) {
      throw new Error("El método de envío es requerido");
    }
  }

  _checkMethodResource(method) {
    if (method === undefined || method === null || !Array.isArray(method) || this._isEmpty(method)) {
      throw new Error("El método para el resource es un array requerido");
    }

    if (!method.includes(this.method)) {
      throw new Error(`El método [${this.method}] para ejecutar el resource no está soportado`);
    }
  }

  _checkResource(resource) {
    if (resource === undefined) {
      throw new Error(`Recurso [${resource}] no encontrado`);
    }
  }

  _checkLocalHeaders(resource) {
    const isHeader = Object.prototype.hasOwnProperty.call(resource, "headers");

    if (isHeader) {
      const contentToken = {};
      const isNotEmptyName = localStorage.getItem(this._nameToken) !== null;

      if (this._activeToken && isNotEmptyName) {
        contentToken.Authorization = `Bearer ${localStorage.getItem(this._nameToken)}`;
      }

      if (resource.headers === undefined || resource.headers === null) {
        this.headers = {
          "Content-Type": "application/json",
          ...contentToken,
        };
        return;
      }

      this.headers = { ...resource.headers, ...contentToken };
    }
  }

  _checkAuthBasic(resource) {
    const isNotDefinedAuth = !Object.prototype.hasOwnProperty.call(resource, "auth");
    // const isNotDefinedUsername = !Object.prototype.hasOwnProperty.call(resource, 'username');
    // const isNotDefinedPassword = !Object.prototype.hasOwnProperty.call(resource, 'password');

    if (isNotDefinedAuth) {
      return {};
    }
    return resource.auth;
  }

  _convertToQueryParams(object, force = false) {
    if (this._isGetDelete() || force) {
      let stringParams = "?";
      for (const el in object) {
        if (Object.prototype.hasOwnProperty.call(object, el)) {
          stringParams += `${el}=${object[el]}&`;
        }
      }
      return stringParams.slice(0, -1);
    }
  }

  _isEmpty(arr) {
    return arr.length <= 0;
  }

  get() {
    this.method = "get";
    return this;
  }

  post() {
    this.method = "post";
    return this;
  }

  put() {
    this.method = "put";
    return this;
  }

  patch() {
    this.method = "patch";
    return this;
  }

  delete() {
    this.method = "delete";
    return this;
  }

  exec() {
    if (this.method === undefined) {
      // console.log('Es undefined');
      throw new Error(`Por favor seleccione un método en su componente`);
    }
    // console.log('Paso');
  }

  async downloadResource(resources, object = {}, name) {
    const resource = { ...this.service.resources()[resources] };
    let queryParams = this._convertToQueryParams(object, true);

    //window.location.href = `${this.path}${resource.uri}${queryParams}`;

    const response = await axios({
      url: `${this.path}${resource.uri}${queryParams}`,
      method: "get",
      data: {},
      headers: { "Content-Type": "application/json" },
      responseType: "blob",
    });

    if (response.headers["content-type"] === "application/json") {
      const blob = new Blob([response.data]);
      const text = await blob.text();
      const res = JSON.parse(text);
      console.log("->", res);
      if (!res.status) {
        await Swal.fire({
          icon: "error",
          title: res.data,
          confirmButtonText: `OK`,
        });
        return;
      }
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    let link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name);
    link.click();
    link.remove();
    //setTimeout(() => window.URL.revokeObjectURL(url), 100);

    //window.location.href = `${this.path}${resource.uri}${queryParams}`;
  }

  async downloadResourceURL(pathUrl, object = {}, name) {
    let queryParams = this._convertToQueryParams(object, true);

    const response = await axios({
      url: `${pathUrl}${queryParams}`,
      method: "get",
      data: {},
      headers: { "Content-Type": "application/json" },
      responseType: "blob",
    });

    if (response.headers["content-type"] === "application/json") {
      const blob = new Blob([response.data]);
      const text = await blob.text();
      const res = JSON.parse(text);
      console.log("->", res);
      if (!res.status) {
        await Swal.fire({
          icon: "error",
          title: res.data,
          confirmButtonText: `OK`,
        });
        return;
      }
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    let link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name);
    link.click();
    link.remove();
    //setTimeout(() => window.URL.revokeObjectURL(url), 100);

    //window.location.href = `${this.path}${resource.uri}${queryParams}`;
  }

  async _localInformation() {
    const body = {
      id: null,
      idCompany: null,
      userName: null,
      userId: null,
      employedId: null,
      employedName: null,
      product: null,
      versionClient: null,
      versionBack: null,
      platform: null,
      platformArchitecture: null,
      browser: null,
      detailBrowser: null,
      serial: null,
      latitude: null,
      longitude: null,
      clientIpv4: null,
      clientIpv6: null,
      methodHttp: null,
      urlClient: null,
      urlBack: null,
      view: null,
    };

    let information = encryptData(body, true);
    this.headers = { ...this.headers, "inhts-subs-tkn": information };
  }

  async execResource(resources, object = {}) {
    let queryParams;

    this._checkMethod();

    this._checkLocalHeaders(this.service.resources()[resources]);

    //--------------------------------------------------------------------------
    await this._localInformation();
    //--------------------------------------------------------------------------

    this._checkResource(this.service.resources()[resources]);

    this._checkUri(this.service.resources()[resources].uri);

    this._checkMethodResource(this.service.resources()[resources].method);

    const resource = { ...this.service.resources()[resources] };

    const authBasic = this._checkAuthBasic(this.service.resources()[resources]);

    queryParams = this._convertToQueryParams(object);

    //console.log('url -- ', `${this.path}${resource.uri}${this._isGetDelete() ? queryParams : ''}`);
    const response = await this._request({
      url: `${this.path}${resource.uri}${this._isGetDelete() ? queryParams : ""}`,
      method: this.method,
      data: this._isGetDelete() ? {} : object,
      headers: this.headers,
      auth: authBasic,
    });

    this.method = null;
    return response;
  }

  async _request({ url, method, data, headers, auth }) {
    //console.log('-}{- ', url, method, data, headers, auth);
    const isDefinedUsername = Object.prototype.hasOwnProperty.call(auth, "username");
    const isDefinedPassword = Object.prototype.hasOwnProperty.call(auth, "password");
    const request = await this._requestAxios({
      url: url,
      method: method,
      data: data,
      headers: headers,
      auth: isDefinedUsername && isDefinedPassword ? auth : null,
      responseType: "json",
    });

    // timeout: 5000
    //console.log('_REQUEST: ', request);
    //console.log('component', this.component);
    return request;
  }

  _onRequestSuccess(response) {
    //console.log('RESPONSE: ', response);
    return response.data;
  }

  _onRequestFailure = async (error) => {
    const { response } = error;
    //console.log('ERROR: ', response);

    if (response.status === 401) {
      const { isConfirmed, isDismissed } = await Swal.fire({
        icon: "error",
        title: response.data.error.message,
        confirmButtonText: `OK`,
      });

      if (isConfirmed || isDismissed) {
        //await this.component.$router.push('/auth/login');
        // console.log('route', this.component);

        localStorage.removeItem(this._nameToken);

        // localStorage.clear();

        window.location.href = process.env.VUE_APP_CLIENT_LOGIN_URL;

        return {
          code: 401,
          error: "Unauthorized",
        };
      }
      //console.log('Alert', res);
    } else {
      return Promise.reject(error);
    }
  };

  /*async _onRequestFailure(error) {
      const { response } = error;
      console.log('ERROR: ', response);

      if (response.status === 401) {
        const { isConfirmed, isDismissed } = await Swal.fire({
          icon: 'error',
          title: response.data.error.message,
          confirmButtonText: `OK`
        });

        if (isConfirmed || isDismissed) {
          // await this.component.$router.push({ path: '/auth/login' });
          // console.log('route', this.component);
          return {
            code: 401,
            error: 'Unauthorized'
          };
        }
        //console.log('Alert', res);
      } else {
        return Promise.reject(error);
      }
      //return response.data;
    }*/

  _isGetDelete() {
    return this.method === "get" || this.method === "delete";
  }
}

export default HttpHandlerService;
