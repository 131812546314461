<template>
  <v-container fluid class="down-top-padding" :style="{ paddingRight: 0, paddingLeft: 0 }">
    <v-expand-transition>
      <BaseBreadcrumb v-if="getShowBreadcrumb" :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    </v-expand-transition>
    <slot name="body"></slot>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BaseBreadcrumbView",
  props: {
    page: {
      type: Object,
      default: () => ({
        title: "Title",
        icon: "default",
      }),
    },
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(["getShowBreadcrumb"]),
  },
};
</script>

<style scoped></style>
