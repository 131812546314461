import CryptoJS from "crypto-js";
import { getTokenCode } from "@/components4x/utils/env";
// eslint-disable-next-line no-unused-vars
import HttpHandlerService from "@/plugins/http_service/httpHandlerService";
// eslint-disable-next-line no-unused-vars
//import LoadService from "../../gquest/services/LoadService";

async function loadPermissions(store) {
  let modules = [];
  const code = getTokenCode();
  console.log(modules);
  console.log(store);
  console.log("-->");
  console.log(code);
  console.log("-->");
  // if (localStorage.getItem(code) !== null) {
  //   const http = new HttpHandlerService(new LoadService(), null, {
  //     token: true,
  //     name: code,
  //   });
  //   const res = await http.get().execResource("load");
  //   if (res.status) {
  //     modules = decryptData(res.data, true);
  //   }
  // }
  //
  // await store.dispatch("updatePermission", modules);
}

// eslint-disable-next-line no-unused-vars
async function loadSettings(store, vuetify) {
  // await new Promise((resolve) => setTimeout(resolve, 3000));
  // const id = localStorage.getItem('lrd5qwlr674');
  // if (id === null) return;
  //
  // const decryptId = decryptData(id);
  //
  // const config = await fire
  //   .firestore()
  //   .collection('users')
  //   .doc(decryptId)
  //   .get();
  //
  // if (!config.exists) return;
  //
  // console.log(vuetify, vuetify);
  //
  // vuetify.framework.theme.dark = config.data().themedark;
  // store.commit('SET_SIDEBAR_COLOR', config.data().sidebarcolor);
  // store.commit('SET_THEME_COLOR', config.data().themecolor);
  // await store.dispatch('updateRole', config.data().role);
  // await store.dispatch('updateProfile', config.data().profile);
}

function decryptData(data, isJson = false) {
  if (data === null) return null;
  let encrypted = JSON.parse(atob(data.toString()));

  let decrypted = CryptoJS.AES.decrypt(encrypted.value, CryptoJS.enc.Base64.parse(process.env.VUE_APP_ENCRYPT_KEY.toString()), {
    iv: CryptoJS.enc.Base64.parse(encrypted.iv),
  });

  let decrypt = decrypted.toString(CryptoJS.enc.Utf8);

  return isJson ? JSON.parse(decrypt) : decrypt;
}

// eslint-disable-next-line no-unused-vars
function encryptData(data, isJson = false) {
  const value = isJson ? JSON.stringify(data) : `(s)${data}`;

  let iv = CryptoJS.lib.WordArray.random(16);
  let key = CryptoJS.enc.Base64.parse(process.env.VUE_APP_ENCRYPT_KEY.toString());
  let options = {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  };
  let encrypted = CryptoJS.AES.encrypt(value, key, options);
  encrypted = encrypted.toString();
  iv = CryptoJS.enc.Base64.stringify(iv);
  let result = {
    iv: iv,
    value: encrypted,
    mac: CryptoJS.HmacSHA256(iv + encrypted, key).toString(),
  };
  result = JSON.stringify(result);
  result = CryptoJS.enc.Utf8.parse(result);
  console.log("***********************************");
  console.log(JSON.stringify(result));
  console.log(CryptoJS.enc.Utf8.parse(result));
  console.log(CryptoJS.enc.Base64.stringify(result));
  console.log("***********************************");
  return CryptoJS.enc.Base64.stringify(result);
}

function encryptAES(data, key = process.env.VUE_APP_ENCRYPT_KEY.toString()) {
  if (data === null || data === "") return null;
  return CryptoJS.AES.encrypt(data, key).toString();
}

function decryptAES(data, key = process.env.VUE_APP_ENCRYPT_KEY.toString()) {
  if (data === null || data === "") return null;
  const bytes = CryptoJS.AES.decrypt(data, key);
  return bytes.toString(CryptoJS.enc.Utf8);
}

function pathImage(path) {
  const host = `${process.env.VUE_APP_BACK_URL}/`;
  const route = path.split("-").join("/");
  return `${host}${route}`;
}

export { decryptData, encryptData, encryptAES, decryptAES, pathImage, loadPermissions, loadSettings };
