<template>
  <Icon :icon="icon" :color="color" :width="width" height="" />
</template>

<script>
import { Icon } from "@iconify/vue2/dist/offline";
export default {
  name: "ExIcon",
  components: { Icon },
  props: {
    icon: {
      type: String,
      default: "none",
    },
    color: {
      type: String,
      default: "primary",
    },
    width: {
      type: String,
      default: "24",
    },
  },
  computed: {
    colorDefault() {
      return this.$vuetify.theme.light.primary;
    },
  },
};
</script>

<style scoped></style>
