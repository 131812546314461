<template>
  <v-app id="materialpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
    <router-view />
  </v-app>
</template>

<script>
import { getInteractionMapping } from "@/components4x/utils/env";

export default {
  name: "App",
  data: () => ({
    time: null,
    position: null,
  }),
  components: {},
  methods: {
    resetTimer() {
      // console.log("alguien presiono algo?");
      clearTimeout(this.time);
      // this.time = setTimeout(this.timer, 5000)
      this.time = setTimeout(this.timer, 900000); // 15 minutos
    },
    timer() {
      console.log("que pasó te quedaste dormido?");
    },
    positionSuccess(position) {
      const coords = position.coords;

      console.log("Capturando posicion");
      console.table(coords);
    },
    positionError(error) {
      console.table(error);
      console.warn("ERROR position (" + error.code + "): " + error.message);
    },
  },
  mounted() {
    if (getInteractionMapping()) {
      window.addEventListener("load", this.resetTimer, true);
      let events = ["mousedown", "mousemove", "touchstart", "click", "keypress", "scroll"];
      events.forEach((name) => {
        window.addEventListener(name, this.resetTimer, true);
      });
    }
  },
  updated() {
    // console.log("actualizado el componente ");
    clearTimeout(this.time);
  },
  beforeDestroy() {
    // console.log("antes de destruir el componente ");
    navigator.geolocation.clearWatch(this.position);
    clearTimeout(this.time);
  },
  created() {
    let PositionOptions = {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: 0,
    };
    // console.log("---------------------------------------------------------------");
    // encryptData("hola como esas ?!%$&()?¡");
    // console.log("---------------------------------------------------------------");
    // encryptData(JSON.stringify({ jersson: true, mendoza: "linares ?!##$%#%$&/&/", nana: 234 }));
    // console.log("---------------------------------------------------------------");

    console.log("---------------------------------------------------------------");
    console.log(navigator.platform.toUpperCase());
    console.log(navigator.vendor.toUpperCase());
    console.log(navigator.userAgent.toUpperCase());
    console.log(location.href);
    console.log(navigator);
    console.log(location);
    console.log(window);
    console.log("---------------------------------------------------------------");

    this.position = navigator.geolocation.watchPosition(this.positionSuccess, this.positionError, PositionOptions);
  },
};
</script>
