import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import Vuebar from "vuebar";
import shortkey from "vue-shortkey";
import "./plugins/base";
import "./plugins/icons";
import VueSkycons from "vue-skycons";
import InstantSearch from "vue-instantsearch";
import LoadSetting from "@/components4x/authentication/LoadSetting";
import "@/globalComponents.js";
import { loadPermissions, loadSettings } from "@/components4x/utils/utils4x";
import { getTokenCode } from "@/components4x/utils/env";
import "./registerServiceWorker";

//PLUGIN ENIGMA4X
import httpService4X from "./plugins/http_service/httpInstallService";

Vue.use(httpService4X, {
  token: true,
  name: getTokenCode(),
  store,
  login: "/login",
  unauthorized: "/unauthorized",
});

Vue.use(VueSkycons, {
  color: "#1e88e5",
});
Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.use(shortkey);

let instanceLoading = null;
// eslint-disable-next-line no-unused-vars
let instanceApp = null;

async function initializeApp() {
  instanceLoading = new Vue({
    render: (h) => h(LoadSetting),
  }).$mount("#loadinggmo");

  await loadSettings(store, vuetify);
  await loadPermissions(store);
}

initializeApp().then(() => {
  instanceLoading.$destroy();
  instanceLoading.$el.parentNode.removeChild(instanceLoading.$el);
  instanceApp = new Vue({
    vuetify,
    store,
    router,
    render: (h) => h(App),
  }).$mount("#app");
});
