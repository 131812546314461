function getTokenCode() {
  return process.env.VUE_APP_TOKEN_CODE ?? "token";
}

function getNodeMode() {
  return process.env.NODE_ENV ?? "development";
}

function getInteractionMapping() {
  return !!(process.env.VUE_APP_INTERACTION_MAPPING ?? "false" === "true");
}

export { getTokenCode, getNodeMode, getInteractionMapping };
