/*------------- PRUEBAS DE COMPATIBILIDAD CON ICONOS OFFLINE -----------------*/
import { addCollection } from "@iconify/vue2/dist/offline";
import mdiCogOutline from "@iconify-icons/mdi/cog-outline";
import mdiLogout from "@iconify-icons/mdi/logout";
import mdiBookClockOutline from "@iconify-icons/mdi/book-clock-outline";
import mdiEye from "@iconify-icons/mdi/eye";
import mdiEyeOff from "@iconify-icons/mdi/eye-off";
import mdiWeb from "@iconify-icons/mdi/web";
import mdiChevronRight from "@iconify-icons/mdi/chevron-right";
import mdiFilterMenu from "@iconify-icons/mdi/filter-menu";
import mdiBell from "@iconify-icons/mdi/bell";
import mdiMessage from "@iconify-icons/mdi/message";
import mdiMagnify from "@iconify-icons/mdi/magnify";
import mdiCog from "@iconify-icons/mdi/cog";
import mdiBroom from "@iconify-icons/mdi/broom";
import mdiDotsVertical from "@iconify-icons/mdi/dots-vertical";
import mdiClose from "@iconify-icons/mdi/close";
import mdiMicrosoftExcel from "@iconify-icons/mdi/microsoft-excel";
import mdiPlus from "@iconify-icons/mdi/plus";
import mdiMPencilOutline from "@iconify-icons/mdi/pencil-outline";
import mdiDeleteOutline from "@iconify-icons/mdi/delete-outline";
import mdiChartBoxOutline from "@iconify-icons/mdi/chart-box-outline";
import mdiAccount from "@iconify-icons/mdi/account";
import mdiAccountCancel from "@iconify-icons/mdi/account-cancel";
import mdiRun from "@iconify-icons/mdi/run";
import mdiCurrencyUsd from "@iconify-icons/mdi/currency-usd";
import mdiCurrencyUsdOff from "@iconify-icons/mdi/currency-usd-off";
import mdiCancel from "@iconify-icons/mdi/cancel";
import mdiCheck from "@iconify-icons/mdi/check";
import mdiAccountSearch from "@iconify-icons/mdi/account-search";
import mdiDelete from "@iconify-icons/mdi/delete";
import mdiAplicationImport from "@iconify-icons/mdi/application-import";
import mdiFileDownloadOutline from "@iconify-icons/mdi/file-download-outline";
import mdiFileUpload from "@iconify-icons/mdi/file-upload";
import mdiChevronDown from "@iconify-icons/mdi/chevron-down";
import mdiChevronUp from "@iconify-icons/mdi/chevron-up";
import mdiCloseBox from "@iconify-icons/mdi/close-box";
import mdiMinusBox from "@iconify-icons/mdi/minus-box";
import mdiCheckboxBlankOutline from "@iconify-icons/mdi/checkbox-blank-outline";
import mdiCalendar from "@iconify-icons/mdi/calendar";
import mdiArrangeSendToBack from "@iconify-icons/mdi/arrange-send-to-back";
import mdiAccountGroupOutline from "@iconify-icons/mdi/account-group-outline";

delete addCollection({
  icons: {
    "custom-mdi-cog-outline": mdiCogOutline,
    "custom-mdi-logout": mdiLogout,
    "custom-mdi-book-clock-outline": mdiBookClockOutline,
    "custom-mdi-eye": mdiEye,
    "custom-mdi-eye-off": mdiEyeOff,
    "custom-mdi-web": mdiWeb,
    "custom-mdi-chevron-right": mdiChevronRight,
    "custom-mdi-filter-menu": mdiFilterMenu,
    "custom-mdi-bell": mdiBell,
    "custom-mdi-message": mdiMessage,
    "custom-mdi-magnify": mdiMagnify,
    "custom-mdi-cog": mdiCog,
    "custom-mdi-broom": mdiBroom,
    "custom-mdi-dots-vertical": mdiDotsVertical,
    "custom-mdi-close": mdiClose,
    "custom-mdi-microsoft-excel": mdiMicrosoftExcel,
    "custom-mdi-plus": mdiPlus,
    "custom-mdi-pencil-outline": mdiMPencilOutline,
    "custom-mdi-delete-outline": mdiDeleteOutline,
    "custom-mdi-chart-box-outline": mdiChartBoxOutline,
    "custom-mdi-account": mdiAccount,
    "custom-mdi-account-cancel": mdiAccountCancel,
    "custom-mdi-run": mdiRun,
    "custom-mdi-currency-usd": mdiCurrencyUsd,
    "custom-mdi-currency-usd-off": mdiCurrencyUsdOff,
    "custom-mdi-cancel": mdiCancel,
    "custom-mdi-check": mdiCheck,
    "custom-mdi-account-search": mdiAccountSearch,
    "custom-mdi-delete": mdiDelete,
    "custom-mdi-application-import": mdiAplicationImport,
    "custom-mdi-file-download-outline": mdiFileDownloadOutline,
    "custom-mdi-file-upload": mdiFileUpload,
    "custom-mdi-chevron-down": mdiChevronDown,
    "custom-mdi-chevron-up": mdiChevronUp,
    "custom-mdi-close-box": mdiCloseBox,
    "custom-mdi-minus-box": mdiMinusBox,
    "custom-mdi-checkbox-blank-outline": mdiCheckboxBlankOutline,
    "custom-mdi-calendar": mdiCalendar,
    "custom-mdi-arrange-send-to-back": mdiArrangeSendToBack,
    "custom-mdi-account-group-outline": mdiAccountGroupOutline,
  },
});

// addIcon("custom-mdi-cog-outline", mdiCogOutline);
// addIcon("custom-mdi-logout", mdiLogout);
// addIcon("custom-mdi-book-clock-outline", mdiBookClockOutline);
// addIcon("custom-mdi-eye", mdiEye);
// addIcon("custom-mdi-eye-off", mdiEyeOff);
// addIcon("custom-mdi-web", mdiWeb);

/*------------- PRUEBAS DE COMPATIBILIDAD CON ICONOS OFFLINE -----------------*/
