<template>
  <v-row class="page-breadcrumb">
    <v-col cols="12" sm="12" class="pa-0">
      <v-card class="px-2 custom-shaodow">
        <v-toolbar dense flat class="transparent pa-0" height="50px">
          <v-toolbar-title class="py-3 px-2">
            <v-breadcrumbs :items="breadcrumbs" class="pa-0" large>
              <template v-slot:divider v-if="breadcrumbs">
                <Icon icon="custom-mdi-chevron-right" color="primary" width="18" />
              </template>
            </v-breadcrumbs>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-card>
    </v-col>
  </v-row>
  <!--  <v-row class="page-breadcrumb">-->
  <!--    <v-col cols="12" sm="12" class="pa-0">-->
  <!--      <v-card class="px-2 custom-shaodow">-->
  <!--        <v-toolbar dense flat class="transparent pa-0" height="40px">-->
  <!--          <v-toolbar-title class="pt-3">-->
  <!--            <span class="text&#45;&#45;primary">{{ title }}</span>-->
  <!--          </v-toolbar-title>-->
  <!--          <v-toolbar-title class="toobar-extension transparent pb-3" slot="extension">-->
  <!--            <v-breadcrumbs :items="breadcrumbs" class="pa-0">-->
  <!--              <template v-slot:divider v-if="breadcrumbs">-->
  <!--                <v-icon>mdi-chevron-right</v-icon>-->
  <!--              </template>-->
  <!--            </v-breadcrumbs>-->
  <!--            <slot></slot>-->
  <!--          </v-toolbar-title>-->
  <!--          <v-spacer></v-spacer>-->
  <!--        </v-toolbar>-->
  <!--      </v-card>-->
  <!--    </v-col>-->
  <!--  </v-row>-->
</template>
<script>
import { Icon } from "@iconify/vue2/dist/offline";

export default {
  name: "BaseBreadcrumb",
  components: { Icon },
  props: {
    title: String,
    breadcrumbs: Array,
    icon: String,
  },
  data: () => ({}),
};
</script>
<style lang="scss">
.page-breadcrumb {
  //margin: -24px -25px 27px -25px;
  margin: -24px -25px 20px -25px;
}
</style>
