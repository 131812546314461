<template>
  <!--  <v-card class="mb-7">-->
  <v-card>
    <template v-if="showTitle">
      <v-toolbar flat>
        <v-toolbar-title> {{ heading }} </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider></v-divider>
    </template>
    <div class="pa-4">
      <slot />
    </div>
  </v-card>
</template>

<script>
export default {
  name: "BaseCard",
  props: {
    heading: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
  computed: {
    showTitle() {
      return this.heading.length > 1;
    },
  },
  methods: {},
};
</script>
