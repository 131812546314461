"use strict";

import HttpHandlerService from "./httpHandlerService";

const httpService4X = {};

const permission4x = {
  state: () => ({ permission: [], role: "", profile: [] }),
  mutations: {
    UPDATE_PERMISSION(state, payload) {
      state.permission = payload;
    },
    UPDATE_ROLE(state, payload) {
      state.role = payload;
    },
    UPDATE_PROFILE(state, payload) {
      state.profile = payload;
    },
  },
  actions: {
    updatePermission({ commit }, permission) {
      commit("UPDATE_PERMISSION", permission);
    },
    updateRole({ commit }, role) {
      commit("UPDATE_ROLE", role);
    },
    updateProfile({ commit }, profile) {
      commit("UPDATE_PROFILE", profile);
    },
  },
  getters: {
    getPermissions(state) {
      return state.permission;
    },
    getRole(state) {
      return state.role;
    },
    getProfile(state) {
      return state.profile;
    },
    getFullPermissions(state) {
      return [...state.permission, ...state.profile];
    },
  },
};

httpService4X.install = (Vue, options = {}) => {
  if (Object.prototype.hasOwnProperty.call(options, "store")) {
    options.store.registerModule("permission4x", permission4x);
  } else {
    throw new Error("El plugin depende de la store de Vuex");
  }

  Vue.prototype.$httpService = (service, component) => {
    return new HttpHandlerService(service, component, options);
  };

  Vue.directive("auth-acl-if", {
    bind(el, binding, vNode) {
      vNode["acl4X"] = true;
      vNode["aclc4X"] = "if";
    },
  });

  Vue.directive("auth-acl-else", {
    bind(el, binding, vNode) {
      vNode["acl4X"] = true;
      vNode["aclc4X"] = "else";
    },
  });

  Vue.directive("auth-acl", {
    inserted(el, binding, vNode) {
      if (options.store.getters.getRole === "*") return;

      let conditional = false;
      let ifNode = null;
      let elseNode = null;

      if (Object.prototype.hasOwnProperty.call(binding, "arg") && binding.arg === "conditional") {
        conditional = true;

        const filterIfNode = vNode.children.filter((el) => el.acl4X && el.aclc4X === "if");
        const filterElseNode = vNode.children.filter((el) => el.acl4X && el.aclc4X === "else");

        ifNode = filterIfNode.length > 0 ? filterIfNode[0].elm : null;
        elseNode = filterElseNode.length > 0 ? filterElseNode[0].elm : null;
      }

      let removeChild = document.createComment(" ");

      const authorization = [...options.store.getters.getPermissions, ...options.store.getters.getProfile];

      if (typeof binding.value !== "string") throw new Error("El valor debe ser de tipo String");

      let bindValue = binding.value;

      const index = authorization.indexOf(bindValue);

      if (index === -1) {
        if (conditional) {
          if (ifNode !== null) {
            el.replaceChild(removeChild, ifNode);
          }
        } else {
          if (el.parentNode) {
            el.parentNode.replaceChild(removeChild, el);
          }
        }
      } else {
        if (conditional) {
          if (elseNode !== null) {
            el.replaceChild(removeChild, elseNode);
          }
        }
      }
    },
  });

  Vue.mixin({
    beforeRouteEnter(to, from, next) {
      // console.log("to", to);
      // console.log("from", from);
      // console.log("next", next);
      //----------------------------------------------------------------------------------------------------------------
      const ref = options.token ? localStorage.getItem(options.name) : null;

      // console.log("ref", ref);
      // console.log("to.fullPath", to.fullPath);
      // console.log("options.login", options.login);
      if (to.fullPath === options.login && ref === null) {
        next();
      }
      if (ref === null) {
        next({ path: options.login });
      }

      if (!Object.prototype.hasOwnProperty.call(to, "meta")) {
        throw new Error("El plugin necesita el [meta.code] en el Vue Router");
      }
      if (!Object.prototype.hasOwnProperty.call(to.meta, "code")) {
        throw new Error("El plugin necesita el [meta.code] en el Vue Router");
      }

      const authorization = [...options.store.getters.getFullPermissions];

      const code = to.meta.code;

      const index = code === "*" ? 1 : authorization.indexOf(to.meta.code);

      if (index === -1) {
        next({ path: options.unauthorized });
      } else {
        next();
      }
    },
  });
};

export default httpService4X;
